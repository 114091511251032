import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "titleInput", "content", "drawer", "downloadsTable", "errorMessage", 
    "articlesMenu", "articlesCount", "articlesButton", "articleTitle", 
    "articleError", "submitButton", "responseData", "modalFooter",
    "itemDisplay", "itemForm", "itemEditButton"
  ]

  static values = {
    collectionId: String,
    canUpdate: Boolean,
    weeklyDownloadsUrl: String
  }

  connect() {
    this.pollInterval = null
    this.loadArticles()
  }

  disconnect() {
    this.stopPolling()
  }

  async saveTitle(event) {
    const value = event.target.value.trim()
    if (!value) return
    
    try {
      const response = await fetch(`/collections/${this.collectionIdValue}/update_title`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'text/html'
        },
        body: JSON.stringify({ title: value })
      })

      if (!response.ok) throw new Error('Update failed')
      await this.loadContent()
      
      // Show success indicator
      event.target.classList.add('success')
      setTimeout(() => event.target.classList.remove('success'), 2000)

    } catch (error) {
      console.error('Failed to save title:', error)
      event.target.classList.add('error')
      setTimeout(() => event.target.classList.remove('error'), 2000)
    }
  }

  handleTitleKeyup(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.target.blur()
    }
  }

  async toggleVisibility(event) {
    const button = event.currentTarget
    try {
      const response = await fetch(`/collections/${this.collectionIdValue}/toggle_visibility`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        }
      })

      if (!response.ok) throw new Error('Update failed')
      const data = await response.json()
      
      // Update button text
      button.textContent = data.visibility === 1 ? 'Make Private' : 'Make Public'

    } catch (error) {
      console.error('Failed to toggle visibility:', error)
    }
  }

  async loadContent() {
    try {
      const response = await fetch(`/collections/${this.collectionIdValue}/info`, {
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      if (!response.ok) throw new Error('Failed to load content')
      const html = await response.text()
      this.contentTarget.innerHTML = html
    } catch (error) {
      console.error('Failed to load content:', error)
    }
  }

  openDownloads(event) {
    event.preventDefault()
    this.drawerTarget.classList.remove('hidden')
    this.loadDownloads()
    this.startPolling()
  }

  closeDownloads() {
    this.drawerTarget.classList.add('hidden')
    this.stopPolling()
  }

  startPolling() {
    if (this.pollInterval) return
    this.pollInterval = setInterval(() => {
      this.loadDownloads()
    }, 3000) // Poll every 3 seconds
  }

  stopPolling() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
      this.pollInterval = null
    }
  }

  async loadDownloads() {
    try {
      const response = await fetch(this.weeklyDownloadsUrlValue)
      if (!response.ok) throw new Error('Failed to load downloads')
      const downloads = await response.json()
      
      // Store the current data for comparison
      this.downloadsTableTarget.dataset.downloads = JSON.stringify(downloads)
      this.downloadsTableTarget.innerHTML = this.renderDownloads(downloads)
      
      // Check if we should continue polling
      const hasProcessingDownloads = downloads.some(d => 
        ['initializing', 'processing'].includes(d.status)
      )
      if (!hasProcessingDownloads) {
        this.stopPolling()
      }
      
    } catch (error) {
      console.error('Failed to load downloads:', error)
      this.errorMessageTarget.textContent = error.message
      this.errorMessageTarget.classList.remove('hidden')
      this.stopPolling()
    }
  }

  async createDownload(event) {
    const week = event.target.dataset.weekParam
    const year = event.target.dataset.yearParam

    try {
      const response = await fetch(`${this.weeklyDownloadsUrlValue}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ week, year })
      })

      if (!response.ok) throw new Error('Failed to create download')
      
      const result = await response.json()
      
      // Start polling for updates
      this.startPolling()
      
      // Immediately update the UI with initializing state
      const downloads = JSON.parse(this.downloadsTableTarget.dataset.downloads || '[]')
      const updatedDownloads = downloads.map(download => {
        if (download.week === parseInt(week) && download.year === parseInt(year)) {
          return {
            ...download,
            id: result.download_id,
            status: 'initializing',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          }
        }
        return download
      })
      
      // Update the table and store the current data
      this.downloadsTableTarget.dataset.downloads = JSON.stringify(updatedDownloads)
      this.downloadsTableTarget.innerHTML = this.renderDownloads(updatedDownloads)
      
    } catch (error) {
      console.error('Failed to create download:', error)
      this.errorMessageTarget.textContent = 'Failed to create download'
      this.errorMessageTarget.classList.remove('hidden')
    }
  }

  async resetDownload(event) {
    const downloadId = event.target.dataset.downloadIdParam
    
    try {
      const response = await fetch(`${this.weeklyDownloadsUrlValue}/${downloadId}/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })

      if (!response.ok) throw new Error('Failed to reset download')
      
      // Refresh the downloads list
      this.loadDownloads()
    } catch (error) {
      console.error('Failed to reset download:', error)
      this.errorMessageTarget.textContent = 'Failed to reset download'
      this.errorMessageTarget.classList.remove('hidden')
    }
  }

  async enlargePhoto(data) {
    const photoId = data.target.dataset.photoId
    const caption = data.target.dataset.caption
    const event = new CustomEvent("openModal", {
      detail: {
        url: `/photos/${photoId}/modal`,  // No need for xhr=true
        modalId: '#enlargePhotoModal',
        title: 'Viewing Photo',
        subtitle: caption
      }
    })
    document.dispatchEvent(event)
  }

  renderDownloads(downloads) {
    if (!downloads.length) {
      return `<tr><td colspan="4" class="text-center">

      <a href="/admin/collections/${this.collectionIdValue}">Generate new downloads in admin.</a>
      <br/>
      Downloads can be generated when at least 1 photo is added to the collection.
      </td></tr>`
    }

    return downloads.map(download => `
      <tr>
        <td>[${download.id}]</td>
        <td>${download.start_date} - ${download.end_date}</td>
        <td>${download.total_images} images</td>
        <td>${this.renderStatus(download)}</td>
        <td>${this.renderActions(download)}</td>
      </tr>
    `).join('')
  }

  renderStatus(download) {
    const statusMap = {
      not_created: '<span class="badge badge-secondary">Not Created</span>',
      initializing: '<span class="badge badge-info">Initializing...</span>',
      processing: '<span class="badge badge-info">Processing</span>',
      finished: '<span class="badge badge-success">Ready</span>',
      failed: '<span class="badge badge-danger">Failed</span>'
    }
    return statusMap[download.status] || statusMap.not_created
  }

  renderActions(download) {
    if (download.status === 'not_created') {
      return `
        <button class="btn btn-sm btn-primary" 
                data-action="collection#createDownload" 
                data-week-param="${download.week}" 
                data-year-param="${download.year}">
          Create
        </button>
      `
    }

    if (download.status === 'finished') {
      if (!download.download_link) {
        console.log('Finished but no link available', download)
        return `
          <span class="text-muted">
            <i class="fa fa-spinner fa-spin"></i> Finished but no link available
          </span>
          <button class="btn btn-sm btn-warning" 
                  data-action="collection#resetDownload" 
                  data-download-id-param="${download.id}">
            Retry
          </button>
        `
      }
      return `
        <a href="${download.download_link}" 
           class="btn btn-sm btn-success" 
           target="_blank">
          Download
        </a>
      `
    }

    if (download.status === 'failed') {
      return `
        <button class="btn btn-sm btn-warning" 
                data-action="collection#resetDownload" 
                data-download-id-param="${download.id}">
          Retry
        </button>
      `
    }

    if (['initializing', 'processing'].includes(download.status)) {
      return '<span class="text-muted"><i class="fa fa-spinner fa-spin"></i> Processing...</span>'
    }

    return ''
  }

  async toggleMenu(event) {
    if (event) event.preventDefault()
    console.log('Toggle menu clicked')

    // Simple toggle of show class
    this.articlesMenuTarget.classList.toggle('show')
    
    if (this.articlesMenuTarget.classList.contains('show')) {
      await this.loadArticles()
    }
  }

  async loadArticles() {
    console.log('Loading articles...')
    this.articlesCountTarget.textContent = '...'
    
    try {
      const response = await fetch(`/collections/${this.collectionIdValue}/articles.json`)
      if (!response.ok) throw new Error('Failed to load articles')
      const articles = await response.json()
      console.log('Articles loaded:', articles)
      
      this.articlesCountTarget.textContent = articles.length
      if (articles.length === 0) {
        this.articlesButtonTarget.classList.add('text-muted')
      } else {
        this.articlesButtonTarget.classList.remove('text-muted')
      }
      
      this.articlesMenuTarget.innerHTML = this.renderArticlesMenu(articles)
      console.log('Menu rendered')
    } catch (error) {
      console.error('Failed to load articles:', error)
      this.articlesCountTarget.textContent = '0'
      this.articlesButtonTarget.classList.add('text-muted')
      this.articlesMenuTarget.innerHTML = '<li class="dropdown-header text-danger">Failed to load articles</li>'
    }
  }

  renderArticlesMenu(articles) {
    let content = `
      <div class="dropdown-header d-flex justify-content-between align-items-center">
        <span>${articles.length ? 'Articles' : 'No articles yet'}</span>
        <button type="button" class="close" data-action="collection#toggleMenu">&times;</button>
      </div>
    `

    if (articles.length) {
      content += `
        <div class="articles-grid">
          ${articles.map(article => `
            <div class='flex w-100 justify-between items-center'>
              <a href="/articles/${article.tag}" class="dropdown-item tr" style="min-width:220px">
                ${article.title}
              </a>
              <a href="/articles/${article.tag}/edit" class="dropdown-item" style="max-width:60px">
                <i class="fa fa-pencil"></i>
              </a>
            </div>
          `).join('')}
        </div>
      `
    }

    return content
  }

  async openNewArticleModal(event) {
    if (event) event.preventDefault()
    
    // Reset form state
    this.resetModal()
    
    $('#new-article-modal').modal('show')
  }

  resetModal() {
    // Reset all form fields and error messages
    if (this.hasArticleTitleTarget) {
      this.articleTitleTarget.value = ''
    }
    if (this.hasArticleErrorTarget) {
      this.articleErrorTarget.textContent = ''
    }
    if (this.hasResponseDataTarget) {
      this.responseDataTarget.textContent = ''
    }
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false
    }
    if (this.hasModalFooterTarget) {
      this.modalFooterTarget.style.display = 'block'
    }
  }

  async createArticle(event) {
    if (event) event.preventDefault()
    
    // Show loading state
    this.submitButtonTarget.disabled = true
    this.articleErrorTarget.textContent = ''
    this.responseDataTarget.innerHTML = '<div class="text-center">Creating article...</div>'
    
    const title = this.articleTitleTarget.value.trim()
    if (!title) {
      this.articleErrorTarget.textContent = 'Title is required'
      this.submitButtonTarget.disabled = false
      return
    }
    
    try {
      const response = await fetch('/articles/create_from_collection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ 
          collection_id: this.collectionIdValue,
          article: { title } 
        })
      })
      
      const data = await response.json()
      
      if (response.ok) {
        // Hide footer controls
        this.modalFooterTarget.style.display = 'none'
        // Show success message with buttons
        this.responseDataTarget.innerHTML = `
          <div class="text-center">
            <p>Successfully created article:</p>
            <a href="/articles/${data.tag}/edit" class="btn btn-primary">Edit Article</a>
            <a href="/articles/${data.tag}" class="btn btn-primary">View Article</a>
          </div>
        `
      } else {
        // Show error and re-enable submit
        this.articleErrorTarget.textContent = data.error || 'Failed to create article'
        this.submitButtonTarget.disabled = false
      }
    } catch (error) {
      console.error('Failed to create article:', error)
      this.articleErrorTarget.textContent = 'Failed to create article'
      this.submitButtonTarget.disabled = false
    }
  }

  hideError() {
    this.errorMessageTarget.classList.add('hidden')
  }

  async deletePhoto(event) {
    event.preventDefault()
    
    if (!confirm('Are you sure you want to remove this photo from the collection?')) return
    
    const button = event.currentTarget
    const itemId = button.dataset.itemId
    
    try {
      const response = await fetch(`/collections/${this.collectionIdValue}/remove_item?item_id=${itemId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        }
      })

      if (!response.ok) throw new Error('Failed to delete photo')
      
      // Find the photo item through proper DOM traversal
      const photoItem = button.closest('.photo-item')
      if (photoItem) {
        photoItem.remove()
      } else {
        console.error('Could not find photo item element to remove')
      }

    } catch (error) {
      console.error('Failed to delete photo:', error)
      alert('Failed to delete photo from collection')
    }
  }

  showItemEdit(event) {
    const container = event.target.closest('.collection-item-details')
    const display = container.querySelector('[data-collection-target="itemDisplay"]')
    const form = container.querySelector('[data-collection-target="itemForm"]')
    
    if (display && form) {
      display.classList.add('hidden')
      form.classList.remove('hidden')
    }
  }

  hideItemEdit(event) {
    const container = event.target.closest('.collection-item-details')
    const display = container.querySelector('[data-collection-target="itemDisplay"]')
    const form = container.querySelector('[data-collection-target="itemForm"]')
    
    if (display && form) {
      display.classList.remove('hidden')
      form.classList.add('hidden')
    }
  }

  async saveItemDetails(event) {
    event.preventDefault()
    const form = event.target
    const itemId = form.dataset.itemId
    
    try {
      const formData = new FormData(form)
      const response = await fetch(form.action, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: formData
      })

      if (!response.ok) throw new Error('Update failed')

      // Update the display view with new values
      const container = form.closest('.collection-item-details')
      const display = container.querySelector('[data-collection-target="itemDisplay"]')
      
      if (display) {
        const caption = formData.get('caption')
        const description = formData.get('description')
        const copyright = formData.get('copyright')
        
        display.querySelector('.item-caption').textContent = caption
        display.querySelector('.item-description').textContent = description
        display.querySelector('.item-copyright').textContent = copyright
      }
      
      this.hideItemEdit({ target: form })

      // Show loading overlay
      const photoItem = form.closest('.photo-item')
      const loadingOverlay = photoItem.querySelector('.loading-overlay')
      if (loadingOverlay) {
        loadingOverlay.classList.remove('hidden')
      }
      
      // Reload after a short delay
      setTimeout(() => {
        window.location.reload()
      }, 300)
    } catch (error) {
      console.error('Failed to save item details:', error)
      // Show error message
    }
  }

  toggleOriginalDetails(event) {
    const button = event.currentTarget
    const field = button.dataset.field
    const container = button.closest('.form-group')
    const detailsDiv = container.querySelector(`.original-details[data-field="${field}"]`)
    
    if (detailsDiv) {
      const isHidden = detailsDiv.classList.contains('hidden')
      detailsDiv.classList.toggle('hidden', !isHidden)
      
      // Toggle icon between info-circle and times-circle
      const icon = button.querySelector('i')
      icon.classList.toggle('fa-info-circle', !isHidden)
      icon.classList.toggle('fa-times-circle', isHidden)
    }
  }

}